<template>
	<div class="content">
		<!-- 添加分类 -->
		<a-button class="mb-15" type="primary" @click="add">
		  添加推荐
		</a-button>
		<!-- 表格 -->
		<Table
		:columns="columns" 
		:datasource="datasource" 
		:pagination="pagination"
		@gettabledata="gettabledata" 
		@getshow = "getshow"
		@modify = "modify"
		@del = "del"
		>
		</Table>
		
		
		<!-- 修改 -->
		<Modify ref="modify" :record="record" @gettabledata="gettabledata"  @changeimg = "changeimg" @modifynav="modifynav"></Modify>
		
		<!-- Add -->
		<Add ref="add" :record="record" @changeimg = "changeimg" @modifynav="addnav"></Add>
	</div>
</template>

<script>
	import {mapState,mapGetters,mapMutations,mapActions} from "vuex"
	import Table from "../../components/recommend/table.vue"
	import Modify from "../../components/recommend/modify.vue"
	import Add from "../../components/recommend/add.vue"
	export default {
		components:{
			Table,
			Modify,
			Add
		},
		data() {
			return {
				record:{
					data:"",
					shop:"",
					classify:""
				},
				pagination:{
					pageSize:10,
					current:1,
					totalPage:0,
					total:0,
					keyword:"",
					platform:""
				},
				columns :[
				  {
				    title: '排序',
				    dataIndex: 'sort',
					key:"sort",
				    sorter: true,
				    width: '5%',
				    scopedSlots: { customRender: 'sort' },
					align: 'center' 
				  },
				  {
				    title: 'ID',
					key:"id",
				    dataIndex: 'id',
					align: 'center' 
				  },
				  {
				    title: '名称',
					key:"name",
				    dataIndex: 'name',
					align: 'center' 
				  },
				  {
				    title: '类型',
					key:"type",
				    dataIndex: 'type',
					align: 'center' 
				  },
				  {
				    title: 'ICON',
				  	key:"img",
				    dataIndex: 'img',
					slots: { title: 'img' }, //插槽列
					scopedSlots: { customRender: "img" },//插槽列数据
					align: 'center' 
				  },
				  {
				    title: '是否显示',
					key:"status",
					dataIndex: 'status',
					slots: { title: 'status' }, //插槽列
					scopedSlots: { customRender: "status" },//插槽列数据
					align: 'center' 
				  },
				  {
				    title: '创建时间',
					key:"create_time",
				    dataIndex: 'create_time',
					align: 'center' 
				  },
				  {
				    title: '创建人',
					key:"create_user",
				    dataIndex: 'create_user',
					align: 'center' 
				  },
				  {
				    title: '操作',
					key:"action",
				    dataIndex: 'action',
					slots: { title: 'action' }, //插槽列
					scopedSlots: { customRender: "action" },//插槽列数据
					align: 'center' 
				  },
				],
				datasource:[
					{
						sort:1,
						id:1,
						name:"家电清洗",
						classify:"服务",
						icon:"icon",
						show:false,
						time:"2012-03-34",
						person:"马先生",
						control:"修改"
					},
					{
						sort:2,
						id:2,
						name:"家电产生的的清洗",
						classify:"服务",
						icon:"icon",
						show:true,
						time:"2012-03-34",
						person:"重先生",
						control:"修改"
					},
				]
			}
		},
		created() {
			// 获取表格数据
			this.gettabledata(this.pagination)
		},
		methods:{
			...mapActions({
				getactivity:"appletsetrecommend/getactivity",
				getstatus:"appletsetrecommend/getstatus",
				getupdate:"appletsetrecommend/getupdate",
				getadd:"appletsetrecommend/getadd",
				getdel:"appletsetrecommend/getdel",
				commone:"index/commone",
			}),
			/*
			 *获取数据
			 *pagination 分页参数
			 * 初始化和点击执行
			 */
			async gettabledata(pagination){
				 let data = await this.getactivity(pagination)
				 this.pagination.current = pagination.current
				 this.pagination.total = data.data.total
				 this.pagination.totalPage = data.data.totalPage
				 this.datasource = data.data.data
			
			},
			// 显示和禁止
			async getshow(id){
				let msg = await this.getstatus(id)
				this.$message.success(msg)
				//刷新数据
				this.gettabledata(this.pagination)
			},
			// 打开修改弹窗
			async modify(record){
				console.log("record",record);
				let shop = await this.commone('all_service')
				console.log("所有服务",shop);
				this.record.shop = shop
				// 每一行数据record
				this.record.data = record
				this.$refs.modify.show = true
				
			},
			// 更换图片
			changeimg(payload){
				this.record.data.img = payload
				console.log("payload",this.record.data.img);
			},
			// 打开添加弹窗
			async add(){
				let shop = await this.commone('all_service')
			
				console.log("所有服务",shop);
				// 每一行数据record
				let record = {
					name:"",
					img:"",
					service_id:"",
				}
				this.record.data = record
				this.record.shop = shop
				this.$refs.add.show = true
				
			},
			// 修改提交
			async modifynav(payload){
				console.log(payload,"提交参数");
				let msg = await this.getupdate(payload)
				this.$message.success(msg)
				await this.gettabledata(this.pagination)
				this.$refs.modify.show = false
			},
			// 打开
			// addnav 添加提交
			async addnav(payload){
				console.log(payload,"提交参数");
				let msg = await this.getadd(payload)
				this.$message.success(msg)
				await this.gettabledata(this.pagination)
				this.$refs.add.show = false
			},
			// 删除
			async del(e){
				// 
				console.log(e);
				let msg = await this.getdel(e.id)
				this.$message.success(msg)
				this.gettabledata(this.pagination)
			}
		}
	}
</script>

<style>
</style>
