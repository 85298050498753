<template>
	<div>
		<!-- 
	  bordered :边框线
	  columns：表头
	  slots: { title: '是否显示' }, //插槽列
	  scopedSlots: { customRender: '是否显示' },//插槽列数据
	  @change="handleTableChange" //分页、排序、筛选变化时触发
	  -->
		<a-table 
		bordered 
		:columns="columns" 
		:data-source="datasource" 
		:pagination="pagination" 
		row-key="id"
		@change="handleTableChange"
		>
		
			<template  slot="img" slot-scope="img">
				
				<img class="img-pic" :src="img" alt="" />
				
			</template>	
		
			<template slot="status" slot-scope="text,record">
				<template v-if="record.status == 1">
					<a-popconfirm
						  okText="确定"
						  cancelText="取消"
						  v-if="datasource.length"
						  title="确认禁止?"
						  @confirm="() => getshow(record.id)"
						>
						  <a-button type="primary">显示</a-button>
					</a-popconfirm>
				</template>
				<template v-else>
					<a-popconfirm
						  okText="确定"
						  cancelText="取消"
						  v-if="datasource.length"
						  title="确认显示?"
						  @confirm="() => getshow(record.id)"
						>
						  <a-button type="danger">禁止</a-button>
					</a-popconfirm>
				</template>
			</template>
			
			<template slot="action" slot-scope="text,record">
				<a-button  type="primary" @click="modify(record)">
					{{"修改"}}
				</a-button>
				<a-popconfirm
					okText="确定"
					cancelText="取消"
				     v-if="datasource.length"
				     title="确认显示?"
				     @confirm="() => del(record)"
				   >
				    <a-button class="ml-15" type="danger">
				    	{{"删除"}}
				    </a-button>
				   </a-popconfirm>
				
			</template>

		</a-table>
	</div>
</template>
<script>
	export default {
		props: {
			columns: Array,
			datasource: Array,
			pagination: Object,
			
		},
		data() {
			return {
				show:{
					title:"确认禁止",
					txt:"显示"
				},
			};
		},
		mounted() {

		},
		methods: {
			// 分页点击触发
			handleTableChange(pagination, filters, sorter) {
			  console.log(pagination);
			 this.$emit("gettabledata",pagination)
			},

			// 显示提示框
			getshow(key) {
				console.log("对话框",key);
				this.$emit("getshow", key)
			},
			// 打开操作框
			modify(record) {
				this.$emit("modify", record)
			},
			// 删除操作
			del(record){
				this.$emit("del", record)
			}
		},
	};
</script>

<style lang="less" scoped>


	.set {
		flex-direction: column;

		.setitem {
			width: 100%;

			.sort {
				width: 40%;

				.a-input {
					width: 50px;
					padding-left: 10px;
					margin-left: 5px;
				}
			}

			.switch {
				flex: 1;

				.txt {}

				.a-switch {
					margin-left: 5px;
					padding-left: 10px;
				}
			}
		}
	}
</style>
